<template>
	<div class="page-event-live">
		<div class="page-wrapper" v-if="event">
			<div class="container">
				<!-- Parent -->
				<vs-row vs-w="12">
					<!-- Left -->
					<vs-col vs-lg="12" vs-sm="12">
						<!-- Video -->
						<live-video
							:event="event"
							:isEmbed="true"
							v-if="!isFilterEmbed || (isFilterEmbed && viewParams.includes('video'))">
						</live-video>

						<!-- Surveys -->
						<live-surveys
							:event="event"
							:user="user"
							:participant="participant"
							:eventSettings="eventSettings"
							:isVideoLive="isLiveVideoVisible"
							:isEmbed="true"
							v-if="!isFilterEmbed || (isFilterEmbed && viewParams.includes('survey'))">
						</live-surveys>

						<!-- Question Form -->
						<live-question-form
							:event="event"
							:user="user"
							:participant="participant"
							:eventSettings="eventSettings"
							:isVideoLive="isLiveVideoVisible"
							:isEmbed="true"
							v-if="!isFilterEmbed || (isFilterEmbed && viewParams.includes('question_form'))">
						</live-question-form>

						<!-- Chatbox -->
						<live-chat
							:event="event"
							:participant="participant"
							:eventSettings="eventSettings"
							:isVideoLive="isLiveVideoVisible"
							:isEmbed="true"
							v-if="!isFilterEmbed || (isFilterEmbed && viewParams.includes('chat'))">
						</live-chat>

						<!-- Questions moderated -->
						<live-questions
							:event="event"
							:participant="participant"
							:eventSettings="eventSettings"
							:isEmbed="true"
							:isModerated="true"
							v-if="!isFilterEmbed || (isFilterEmbed && viewParams.includes('moderated_questions'))">
						</live-questions>

						<!-- Questions un-moderated -->
						<live-questions
							:event="event"
							:participant="participant"
							:eventSettings="eventSettings"
							:isEmbed="true"
							:isModerated="false"
							v-if="!isFilterEmbed || (isFilterEmbed && viewParams.includes('unmoderated_questions'))">
						</live-questions>
					</vs-col>
				</vs-row>

				<!-- Loader -->
				<div class="p3 text-center" v-if="isFetching">
					<div class="is-loading large dark"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getAxiosErrorMessage } from '@/lib/helper';
import eventApi from '@/api/event';
import participantApi from '@/api/participant';
import LiveQuestionForm from '@/components/LiveQuestionForm.vue';
import LiveChat from '@/components/LiveChat.vue';
import LiveSurveys from '@/components/LiveSurveys.vue';
import LiveVideo from '@/components/LiveVideo.vue';
import LiveQuestions from '@/components/LiveQuestions.vue';

export default {
  name: 'EventLive',
  components: {
    LiveQuestionForm,
    LiveChat,
    LiveSurveys,
    LiveVideo,
    LiveQuestions,
  },
  props: {
    eventSlug: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      event: null,
      isFetching: false,
      isShowCountdown: true,
      isShowModalRegister: false,
      isShowModalAccessCodeRequired: false,
      isFetchingParticipant: false,
      participant: null,
      isEventNotAvailable: false,
      myIP: null,
    };
  },
  sockets: {
    connect() {
      this.initSocket();
    },
    event_update(payload) {
      Object.assign(this.event, payload);
      this.setBackground();
      this.checkIfCodeRequired();
    },
  },
  methods: {
    initSocket() {
      const payload = {
        room_id: this.room,
      };
      // Join Room
      this.$socket.client.emit('join', payload);
    },
    leaveSocketRoom() {
      this.$socket.client.emit('leave', this.room);
    },
    changeHeightOnLoad() {
      setTimeout(() => {
        this.changeHeight();
      }, 500);
    },
    onResizePage() {
      // eslint-disable-next-line
			$(() => {
        // eslint-disable-next-line
				$(window).resize(() => {
          this.changeHeight();
        });
      });
    },
    changeHeight() {
      // eslint-disable-next-line
			const height = $(window).height();
      // eslint-disable-next-line
			const width = $(window).width();
      if (width <= 900) {
        // eslint-disable-next-line
				$('.interactive-wrapper').css({
          'max-height': height - 400,
          overflow: 'auto',
        });
      } else {
        // eslint-disable-next-line
				$('.interactive-wrapper').css({
          'max-height': '100%',
          overflow: 'auto',
        });
      }
    },
    setBackground() {
      // eslint-disable-next-line
			let bgImage = require('@/assets/images/backgrounds/BG_ALT_04.png');
      if (this.event && this.event.background) bgImage = this.event.background;
      document.body.style.backgroundImage = `url(${bgImage})`;
      document.body.style.backgroundSize = 'cover';
    },
    addBodyClass() {
      document.body.classList.add('background-image');
      this.setBackground();
    },
    removeBodyClass() {
      document.body.classList.remove('background-image');
      document.body.style.backgroundImage = 'none';
      document.body.style.backgroundSize = 'auto';
    },
    fetch() {
      this.fetchDetails();
      this.fetchParticipantMe();
    },
    fetchParticipantMe() {
      if (!this.user) return;
      const params = {
        event_slug: this.eventSlug,
      };
      this.isFetchingParticipant = true;
      const callback = (response) => {
        const participant = response.data;
        this.participant = participant;
        this.isFetchingParticipant = false;
      };
      const errorCallback = () => {
        this.isFetchingParticipant = false;
      };
      participantApi.me(params, callback, errorCallback);
    },
    fetchDetails() {
      this.isFetching = true;
      const callback = (response) => {
        const event = response.data;
        this.event = event;
        this.isFetching = false;
        document.title = event.name;
        this.addBodyClass();
        this.checkIfCodeRequired();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Event'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isFetching = false;
        this.isEventNotAvailable = true;
      };
      eventApi.getBySlug(this.eventSlug, callback, errorCallback);
    },
    showCountdown(toggle) {
      this.isShowCountdown = toggle;
    },
    checkIfCodeRequired() {
      setTimeout(() => {
        if (this.isAccessCodeRequired && !this.isPaid) {
          const accessCodeSaved = localStorage.getItem(this.event.slug);
          if (accessCodeSaved !== undefined) {
            const isSame = accessCodeSaved === this.eventAccessCode;
            if (!isSame) this.isShowModalAccessCodeRequired = true;
          } else {
            this.isShowModalAccessCodeRequired = true;
          }
        } else if (this.isPaid) {
          this.checkAccessCodeParticipant();
        } else if (!this.isAccessCodeRequired) {
          this.isShowModalAccessCodeRequired = false;
        }
      }, 200);
    },
    setParticipant(participant) {
      this.participant = participant;
    },
    setLiveUrl() {
      const LAST_SESSION_URL = 'LAST_SESSION_URL';
      const lastUrl = window.location.href;
      localStorage.setItem(LAST_SESSION_URL, lastUrl);
    },
    checkAccessCodeParticipant() {
      if (!this.user) {
        this.setLiveUrl();
        this.$router.push('/login').catch(() => {});
      }
      if (!this.participant) return;
      const accessCodeSaved = localStorage.getItem(this.event.slug);
      if (accessCodeSaved !== undefined) {
        const isSame = this.participant.code === accessCodeSaved;
        if (!isSame) this.isShowModalAccessCodeRequired = true;
      } else {
        this.isShowModalAccessCodeRequired = true;
      }
    },
    getMyIP() {
      fetch('https://api.ipify.org?format=json')
        .then(x => x.json())
        .then((response) => {
          this.myIP = response.ip;
          setTimeout(() => {
            this.joinEvent();
          }, 1000);
        });
    },
    joinEvent() {
      const params = {
        ip_address: this.myIP,
        browser: this.myIP,
        event_slug: this.eventSlug,
      };
      const callback = () => {
      };
      const errorCallback = () => {
      };
      eventApi.join(params, callback, errorCallback);
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    isOwner() {
      const isOwner = this.user && this.event && this.event.user && this.user.id === this.event.user.id;
      return isOwner;
    },
    userName() {
      const name = this.user ? `${this.user.name}` : 'Home';
      return name;
    },
    room() {
      const room = `event-${this.eventSlug}`;
      return room;
    },
    eventSettings() {
      const settings = this.event && this.event.settings ? JSON.parse(this.event.settings) : '';
      return settings;
    },
    isEventPublished() {
      const isPublished = this.event ? this.event.is_published : true;
      return isPublished;
    },
    isLiveVideoVisible() {
      const isVisible = !this.isShowCountdown && !!this.event && !!this.event.url_video;
      return isVisible;
    },
    isCountdownVisible() {
      return this.isShowCountdown || (!this.event.url_video && !this.isShowCountdown);
    },
    isAccessCodeRequired() {
      const isRequired = this.eventSettings.is_required_access_code || false;
      return isRequired;
    },
    eventAccessCode() {
      const accessCode = this.event.access_code;
      return accessCode;
    },
    isPaid() {
      const isPaid = this.event ? !!this.event.is_paid || this.event.price === 0 : false;
      return isPaid;
    },
    isPreview() {
      const currentURL = window.location.href;
      const url = new URL(currentURL);
      const isPreview = !!url.searchParams.get('preview') || false;
      return isPreview;
    },
    fontHeadingColor() {
      const fontColor = this.eventSettings && this.eventSettings.style && this.eventSettings.style.font_color ? this.eventSettings.style.font_color : null;
      const fontHeadingColor = this.eventSettings && this.eventSettings.style && this.eventSettings.style.font_heading_color ? this.eventSettings.style.font_heading_color : fontColor;
      if (!fontHeadingColor) return '';
      const styles = {
        color: `${fontHeadingColor}`,
      };
      return styles;
    },
    isParticipant() {
      return !!this.participant;
    },
    myBrowser() {
      return this.$browserDetect && this.$browserDetect.meta ? `${this.$browserDetect.meta.name} - ${this.$browserDetect.meta.ua} - ${this.$browserDetect.meta.version}` : null;
    },
    viewParam() {
      return this.$route.query.view;
    },
    isFilterEmbed() {
      return !!this.viewParam;
    },
    viewParams() {
      const params = this.viewParam ? this.viewParam.split(',') : [];
      return params;
    },
  },
  watch: {
    eventSlug() {
      if (this.eventSlug) this.initSocket();
    },
    isPaid() {
      this.checkIfCodeRequired();
    },
    user() {
      if (this.user) this.fetchParticipantMe();
    },
    participant() {
      if (!this.isShowModalAccessCodeRequired && this.participant) this.checkIfCodeRequired();
    },
  },
  mounted() {
    this.changeHeightOnLoad();
    this.onResizePage();
  },
  created() {
    this.fetch();
    this.initSocket();
    this.getMyIP();
  },
  beforeDestroy() {
    this.removeBodyClass();
    this.leaveSocketRoom();
  },
  destroyed() {
    this.removeBodyClass();
  },
};
</script>
