<template>
	<div v-if="show">
		<youtube
			:video-id="event.url_video"
			ref="youtube"
			:resize="true"
			:fitParent="true">
		</youtube>
	</div>
</template>

<script>

export default {
  name: 'LiveVideo',
  components: {
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    event: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
