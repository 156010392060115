<template>
	<modal size="medium" :hide-footer="false" @close="close" v-if="show">
		<div slot="header">
			<h5 class="modal-title">{{ $t('Survey Submitted') }}</h5>
		</div>
		<div slot="body">
			<div class="row">
				<div class="col-md-12">
					<div class="connection-wrapper">
						<div>
							{{ $t('Thank you for your feedback, we really appreciate it') }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div slot="footer">
			<div class="modal-action text-right">
				<vs-button type="relief" @click="close()">{{ $t('Ok') }}</vs-button>
			</div>
		</div>
	</modal>
</template>

<script>
import Modal from '@/components/Modal.vue';

export default {
  name: 'ModalSurveySubmitted',
  components: {
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
