<template>
	<div class="live-chat mb-5" v-if="isEnabled">
		<vs-card>
			<div slot="header">
				<h5>Live Chat</h5>
			</div>
			<div
				class="chat-wrapper"
				:class="isEmbed ? 'max-h-500-i' : ''"
				id="chat-wrapper"
				ref="chatWrapper"
				@scroll="onChatScroll"
				>
				<!-- Fetching -->
				<div class="p3 text-center" v-if="isFetching">
					<div class="is-loading large dark"></div>
				</div>

				<!-- Get older messages -->
				<div class="text-center mb-2" v-if="!isFetching && chats.length > 0 && !isAllFetched">
					<vs-button size="small" color="dark" type="filled" @click="fetchList()">{{ $t('Get Older Messages') }}</vs-button>
				</div>

				<div class="text-center mb-2" v-if="!isFetching && chats.length === 0">
					<div class="text-muted font-bold">{{ $t('Be the first?') }}</div>
					<div class="text-muted">{{ $t('Lets talk here') }}</div>
				</div>

				<!-- List -->
				<div
					class="chat-item flex"
					:class="myMessageClass(chat)"
					v-for="(chat, index) in chats"
					:key="index">
					<div class="chat-avatar" v-if="isAvatarVisible">
						<template v-if="!isPreviousSenderSame(chat)">
							<img class="avatar-image" :src="chat.user_picture" v-if="chat.user_picture">
							<div class="avatar-initial" v-else>{{ generateInitial(chat) }}</div>
						</template>
					</div>
					<div class="chat-content">
						<div class="chat-user-name" v-if="!isPreviousSenderSame(chat)">{{ chat.user_name || $t('Anonymous') }}</div>
						<div class="chat-message" :style="secondaryColor(chat)">{{ chat.content }}</div>
					</div>
				</div>
			</div>
			<div slot="footer" v-if="!isEmbed">
				<form class="chat-action flex" v-on:submit.prevent="send()">
					<vs-input v-filter-input
						class="inputx w-full chat-input"
						ref="chatInput"
						id="chatInput"
						:disabled="isSending || isDisabled"
						v-model="content"
						:placeholder="$t('Press ENTER key to end your message')"
					/>
					<picker
						set="emojione"
						title="Pick your emoji…"
						:style="{ position: 'absolute', bottom: '50px', right: '20px' }"
						:showPreview="false"
						:sheetSize="32"
						:disabled="isSending || isDisabled"
						@select="onEmojiSelect"
						v-if="isShowEmoji"/>
					<vs-icon class="emoji-input" icon="sentiment_satisfied_alt" @click="showEmoji()"></vs-icon>
					<vs-button
						:class="{ 'is-loading': isSending }"
						:disabled="isDisabled"
						class="btn-send"
						color="primary"
						type="filled"
						icon="send"
						@click="send()">
					</vs-button>
				</form>
			</div>
		</vs-card>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getAxiosErrorMessage, duplicateVar } from '@/lib/helper';
import chatApi from '@/api/chat';

import { Picker } from 'emoji-mart-vue';

export default {
  name: 'LiveChat',
  components: {
    Picker,
  },
  props: {
    event: {
      type: Object,
      default: () => null,
    },
    participant: {
      type: Object,
      default: () => null,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
    isVideoLive: {
      type: Boolean,
      default: () => false,
    },
    isEmbed: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isFetching: false,
      isAllFetched: false,
      page: 1,
      limit: 25,
      order_by: 'created_at',
      sort_by: 'desc',
      keyword: '',
      chats: [],
      content: '',
      isSending: false,
      lastChat: null,
      isShowEmoji: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    isEnabled() {
      let isEnabled = this.eventSettings && this.eventSettings.chat && this.eventSettings.chat.is_enabled;
      if (this.isLiveOnly) isEnabled = isEnabled && this.isVideoLive;
      return isEnabled || this.isEmbed;
    },
    isAvatarVisible() {
      return this.eventSettings && this.eventSettings.chat && this.eventSettings.chat.is_display_visible;
    },
    isLiveOnly() {
      return this.eventSettings && this.eventSettings.chat && this.eventSettings.chat.is_on_live_visible;
    },
    isDisabled() {
      return !this.participant || !this.user;
    },
  },
  sockets: {
    chat_create(payload) {
      const chat = payload;
      this.addToList(chat);
    },
    chat_delete(payload) {
      const index = this.chats.findIndex(curr => curr.id === payload);
      if (index !== -1) {
        this.chats.splice(index, 1);
      }
    },
  },
  methods: {
    secondaryColor(chat) {
      const myMessage = this.user && chat.user_id !== this.user.id;
      if (!myMessage) return '';

      const color = this.eventSettings && this.eventSettings.style && this.eventSettings.style.secondary_color;
      if (!color) return '';

      const styles = {
        'background-color': `${color}`,
      };
      return styles;
    },
    myMessageClass(chat) {
      const position = this.user && chat.user_id === this.user.id ? 'right' : 'left';
      const isSame = this.isPreviousSenderSame(chat) ? 'same' : '';
      const myClass = `${position} ${isSame}`;
      return myClass;
    },
    isPreviousSenderSame(chat) {
      const currentIndex = this.chats.findIndex(curr => curr.id === chat.id);
      let isSame = false;
      if (currentIndex !== -1) {
        const previousIndex = currentIndex - 1;
        if (previousIndex !== -1) {
          const previousChat = this.chats[previousIndex];
          isSame = previousChat.user_id === chat.user_id;
        }
      }
      return isSame;
    },
    generateInitial(chat) {
      const words = chat.user_name ? chat.user_name.split(' ') : 'AN';
      let initial = words[0][0] !== undefined ? words[0][0] : 'AN';
      if (words.length > 1) {
        const firstCharacter = words[0][0] !== undefined ? words[0][0] : '';
        // const secondCharacter = words[1][0] !== undefined ? words[1][0] : '';
        initial = `${firstCharacter}`;
      }
      return initial;
    },
    resetInput() {
      this.content = '';
      this.closeEmoji();
      this.setInputFocus();
    },
    send() {
      const content = this.content ? this.content.trim() : '';
      if (!content) return;
      const params = {
        event_id: this.event.id,
        content,
      };
      this.isSending = false;
      const callback = () => {
        this.isSending = false;
        this.resetInput();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Chat'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSending = false;
      };
      chatApi.create(params, callback, errorCallback);
    },
    addToList(chat) {
      this.chats.push(chat);
      setTimeout(() => {
        this.scrollChatToBottom();
        // this.scrollElemToBottom('chat-wrapper');
      }, 250);
    },
    onChatScroll(event) {
      const scrollTop = event.target.scrollTop;
      if (scrollTop === 0 && !this.isFetching && !this.isAllFetched) {
        this.fetchList();
      }
    },
    scrollChatToBottom() {
      const container = this.$refs.chatWrapper;
      container.scrollTop = container.scrollHeight;
    },
    scrollElemToBottom(id) {
      const elem = document.getElementById(id);
      if (elem) {
        const scrollHeight = elem.scrollHeight;
        elem.scrollTop = scrollHeight;
      }
    },
    fetchList() {
      const params = {
        event_id: this.event.id,
        page: this.page,
        limit: this.limit,
        order_by: this.order_by,
        sort_by: this.sort_by,
      };
      if (this.lastChat) params.older_than = this.lastChat.created_at;
      if (this.keyword) params.keyword = this.keyword;
      this.isFetching = true;
      const callback = (response) => {
        const chats = response.data;
        if (chats.length < this.limit) {
          this.isAllFetched = true;
        }
        for (let index = 0; index < chats.length; index++) {
          const chat = chats[index];
          if (this.chats.length === 0 && index === 0) {
            this.lastChat = duplicateVar(chat);
          }
          this.chats.unshift(chat);
        }
        this.page++;
        if (params.page === 1) {
          setTimeout(() => {
            this.scrollElemToBottom('chat-wrapper');
          }, 150);
        }
        this.isFetching = false;
        this.resetInput();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Chat'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isFetching = false;
      };
      chatApi.list(params, callback, errorCallback);
    },
    showEmoji() {
      this.isShowEmoji = !this.isShowEmoji;
    },
    closeEmoji() {
      this.isShowEmoji = false;
    },
    setInputFocus() {
      // eslint-disable-next-line
			$('#chatInput').focus();
    },
    onEmojiSelect(emoji) {
      this.content = `${this.content} ${emoji.native} `;
      this.closeEmoji();
      this.setInputFocus();
    },
  },
  mounted() {
  },
  created() {
    this.fetchList();
  },
  destroyed() {
  },
};
</script>
