<template>
	<div class="live-survey" v-if="isEnabled">
		<vs-card>
			<div slot="header">
				<h5>{{ $t('Survey') }}</h5>
			</div>
			<div v-if="surveyFiltered.length > 0">
				<swiper
					class="survey-swiper"
					ref="mySwiper"
					:options="swiperOptions"
					@slideChangeTransitionStart="onSlideChangeStart"
					>
					<swiper-slide v-for="(survey, indexSurvey) in surveyFiltered" :key="indexSurvey">
						<div class="swiper-slide-item">
							<div class="mb-3">{{ survey.name }}</div>
							<div class="survey-options">
								<button
									class="btn-option mb-2"
									:class="selectedOptionColor(option)"
									:style="secondaryColor(option)"
									:key="`${indexSurvey}${indexOption}`"
									v-for="(option, indexOption) in survey.options"
									@click="setSelectedOption(option)">
									{{ option.name }}
								</button>
							</div>
							<vs-button
								type="relief"
								class="w-full mt-10 btn-submit"
								:class="{ 'is-loading': isSubmitting }"
								:style="primaryColor"
								:disabled="(survey.__meta__.is_survey_submitted > 0 && !isAnswerEditable) || (!user || !participant)"
								@click="submit(survey)"
								v-if="survey.options && survey.options.length > 0">
								{{ $t('Submit') }}
							</vs-button>
						</div>
					</swiper-slide>
					<div class="swiper-pagination" slot="pagination"></div>
					<!-- <div class="swiper-button-prev" slot="button-prev"></div> -->
					<div class="swiper-button-next" slot="button-next">{{ $t('Skip') }}</div>
				</swiper>
			</div>

			<div class="p-5 text-center" v-if="surveyFiltered.length === 0">
				{{ $t('No survey available') }}
			</div>
		</vs-card>

		<!-- Modals -->
		<modal-survey-submitted :show="isShowModalSubmitted" @close="isShowModalSubmitted = false"></modal-survey-submitted>
	</div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import ModalSurveySubmitted from '@/components/modals/ModalSurveySubmitted.vue';
import { getAxiosErrorMessage, duplicateVar } from '@/lib/helper';
import surveyApi from '@/api/survey';

const swiperOptions = {
  slidesPerView: 1,
  spaceBetween: 30,
  centeredSlides: true,
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'fraction',
    renderFraction(currentClass, totalClass) {
      return `Survey <span class="${currentClass}"></span> of <span class="${totalClass}"></span>`;
    },
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
};

export default {
  name: 'LiveSurveys',
  components: {
    Swiper,
    SwiperSlide,
    ModalSurveySubmitted,
  },
  directives: {
    swiper: directive,
  },
  props: {
    event: {
      type: Object,
      default: () => null,
    },
    user: {
      type: Object,
      default: () => null,
    },
    participant: {
      type: Object,
      default: () => null,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
    isVideoLive: {
      type: Boolean,
      default: () => false,
    },
    isEmbed: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      swiperOptions: duplicateVar(swiperOptions),
      surveys: [],
      isFetching: false,
      isShowModalSubmitted: false,
      isSubmitting: false,
      selectedOption: null,
    };
  },
  computed: {
    surveyFiltered() {
      const surveys = this.surveys.filter(curr => curr.is_published);
      return surveys;
    },
    isEnabled() {
      let isEnabled = this.eventSettings && this.eventSettings.survey && this.eventSettings.survey.is_enabled;
      if (this.isLiveOnly) isEnabled = isEnabled && this.isVideoLive;
      return isEnabled || this.isEmbed;
    },
    isLiveOnly() {
      return this.eventSettings && this.eventSettings.survey && this.eventSettings.survey.is_on_live_visible;
    },
    isAnswerEditable() {
      return this.eventSettings && this.eventSettings.survey && this.eventSettings.survey.is_editable_answer;
    },
    primaryColor() {
      const color = this.eventSettings && this.eventSettings.style && this.eventSettings.style.primary_color;
      if (!color) return '';
      const styles = {
        'background-color': `${color}`,
      };
      return styles;
    },
  },
  sockets: {
    survey_create(payload) {
      const item = payload;
      this.addToList(item);
    },
    survey_update(payload) {
      const item = payload;
      const isParticipantIdExist = item.participant_id || item.participant_id !== undefined || item.participant_id !== null;
      if (isParticipantIdExist && this.participant) {
        if (this.participant.id === item.participant_id) this.updateItemList(item);
      } else {
        this.updateItemList(item);
      }
    },
    survey_delete(payload) {
      const item = payload;
      this.deleteItemList(item);
    },
  },
  methods: {
    secondaryColor(option) {
      const color = this.eventSettings && this.eventSettings.style && this.eventSettings.style.secondary_color;
      if (!color) return '';
      let styles = '';
      if (this.selectedOption && this.selectedOption.id === option.id) {
        styles = {
          'background-color': `${color}`,
          'border-color': `${color}`,
        };
      }
      return styles;
    },
    fetchList() {
      const params = {
        limit: 100,
        order_by: 'created_at',
        sort_by: 'asc',
        is_published: 1,
        event_id: this.event.id,
      };
      this.isFetching = true;
      const callback = (response) => {
        const surveys = response.data;
        this.surveys = duplicateVar(surveys);
        this.isFetching = false;
      };
      this.$vs.loading.close();
      const errorCallback = (e) => {
        this.isFetching = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Chat'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      surveyApi.getSurveys(params, callback, errorCallback);
    },
    addToList(item) {
      this.surveys.push(item);
    },
    updateItemList(item) {
      const itemIndex = this.surveys.findIndex(curr => curr.id === item.id);
      if (itemIndex !== -1) Object.assign(this.surveys[itemIndex], item);
      else if (item.is_published) this.surveys.push(item);
    },
    deleteItemList(itemId) {
      const itemIndex = this.surveys.findIndex(curr => curr.id === itemId);
      if (itemIndex !== -1) this.surveys.splice(itemIndex, 1);
    },
    selectedOptionColor(option) {
      return this.selectedOption && this.selectedOption.id === option.id ? 'active' : '';
    },
    setSelectedOption(option) {
      this.selectedOption = option;
    },
    onSlideChangeStart() {
      this.selectedOption = null;
    },
    submit(survey) {
      if (!survey || !this.selectedOption) return;
      const params = {
        survey_id: survey.id,
        option_id: this.selectedOption.id,
      };
      this.isSubmitting = true;
      const callback = () => {
        this.isSubmitting = false;
        this.isShowModalSubmitted = true;
      };
      this.$vs.loading.close();
      const errorCallback = (e) => {
        this.isSubmitting = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Survey'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      surveyApi.submit(params, callback, errorCallback);
    },
  },
  mounted() {
  },
  created() {
    this.fetchList();
  },
  destroyed() {
  },
};
</script>
