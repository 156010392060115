<template>
	<div class="live-questions" v-if="isEnabled">
		<vs-card>
			<div slot="header">
				<h5>{{ isModerated ? $t('Moderated questions') : $t('Un-moderated questions') }}</h5>
			</div>
			<div v-if="filteredQuestions.length > 0">
				<div class="vx-col w-full sm:w-1/1 md:w-1/1 lg:w-1/1 mb-2" :data="question" v-for="(question, index) in filteredQuestions" :key="index">
					<vx-card>
							<vs-row>
								<div class="headerDivider">
								</div>
								<div>
									<div class="flex">
										<div class="mr-2 text-lg text-bold pt-0 mt-0">{{question.name}}</div>
									</div>
									<p class="mb-1 mt-4"> {{question.content}} </p>
								</div>
							</vs-row>
					</vx-card>
				</div>
			</div>

			<div class="p-5 text-center" v-if="filteredQuestions.length === 0">
				{{ $t('No question available') }}
			</div>
		</vs-card>
	</div>
</template>

<script>
import { getAxiosErrorMessage, duplicateVar } from '@/lib/helper';
import questionsApi from '@/api/question';

export default {
  name: 'LiveQuestions',
  components: {
  },
  directives: {
  },
  props: {
    event: {
      type: Object,
      default: () => null,
    },
    user: {
      type: Object,
      default: () => null,
    },
    participant: {
      type: Object,
      default: () => null,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
    isVideoLive: {
      type: Boolean,
      default: () => false,
    },
    isEmbed: {
      type: Boolean,
      default: () => false,
    },
    isModerated: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      questions: [],
      isFetching: false,
      selectedOption: null,
    };
  },
  computed: {
    filteredQuestions() {
      let questions = this.questions.filter(curr => !curr.is_moderated);
      if (this.isModerated) questions = this.questions.filter(curr => curr.is_moderated);
      return questions;
    },
    isEnabled() {
      let isEnabled = this.eventSettings && this.eventSettings.question && this.eventSettings.question.is_enabled;
      if (this.isLiveOnly) isEnabled = isEnabled && this.isVideoLive;
      return isEnabled || this.isEmbed;
    },
    isLiveOnly() {
      return this.eventSettings && this.eventSettings.question && this.eventSettings.question.is_on_live_visible;
    },
  },
  sockets: {
    question_create(payload) {
      this.questions.push(payload);
    },
    question_update(payload) {
      const index = this.questions.findIndex(curr => curr.id === payload.id);
      Object.assign(this.questions[index], payload);
    },
    question_delete(payload) {
      const index = this.questions.findIndex(curr => curr.id === payload);
      if (index !== -1) {
        this.questions.splice(index, 1);
      }
    },
  },
  methods: {
    fetchList() {
      const params = {
        page: 1,
        limit: 100,
        order_by: 'created_at',
        sort_by: 'asc',
        event_slug: this.event.slug,
      };
      this.isFetching = true;
      const callback = (response) => {
        const questions = response.data;
        this.questions = duplicateVar(questions);
        this.isFetching = false;
      };
      this.$vs.loading.close();
      const errorCallback = (e) => {
        this.isFetching = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      questionsApi.list(params, callback, errorCallback);
    },
  },
  mounted() {
  },
  created() {
    this.fetchList();
  },
  destroyed() {
  },
};
</script>
